import React, {useContext, useState} from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import config from "../config";
import {AuthContext} from "../App";

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const { login } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const response = await axios.post(`${config.BASE_API_URL}/api/auth/token`, {
        username,
        password,
      }, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
      });
      login(response.data.access_token, { username });
      history.push('/');
    } catch (error) {
      console.error('Login failed:', error);
      if (error.response && error.response.status === 401) {
        setError('Неверный логин/пароль');
      } else {
        setError('Произошла ошибка. Попробуйте еще раз');
      }
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="card shadow-lg" style={{maxWidth: '400px', width: '100%'}}>
        <div className="card-body p-5">
          <h2 className="text-center text-muted mb-4">ARTOOL AI</h2>
          <h2 className="text-center mb-4">Войти</h2>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Логин</label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="password" className="form-label">Пароль</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="d-grid">
              <button type="submit" className="btn btn-primary">Вход</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;