const config = {
  development: {
    BASE_API_URL: "http://api.artool.local",
  },
  production: {
    BASE_API_URL: "https://api.artool-ai.ru", // Replace with your production API URL
  },
};

const env = process.env.NODE_ENV || "development";

export default config[env];