import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import {Upload, Trash2, Edit2, FileText, List, Eye} from 'lucide-react';
import {toast} from 'react-toastify';
import config from '../config';
import Popup from "../components/Popup";

const FileTable = ({files, onDelete, onRename, onParse, onView}) => {
    const [editingFile, setEditingFile] = useState(null);
    const [newFileName, setNewFileName] = useState('');
    const [showParseModal, setShowParseModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);


    const getStatusIcon = (status) => {
        switch (status) {
            case 'PROCESSED':
                return <span className="status-icon status-processed"></span>;
            case 'PROCESSING':
                return <span></span>
            case 'QUEUED':
                return <span className="status-icon status-processing"></span>;
            case 'PROCESSING_ERROR':
                return <span className="status-icon status-processing-error"></span>;
            default:
                return <span className="status-icon status-unprocessed"></span>;
        }
    };

    const handleParseConfirm = () => {
        onParse(selectedFile.id);
        setShowParseModal(false);
    };

    const handleDeleteConfirm = () => {
        onDelete(selectedFile.id);
        setShowDeleteModal(false);
    };

    const isFileProcessed = (file) => {
        return file.status === 'PROCESSED'
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('ru-RU', options);
    };

    return (
        <>
            <table className="file-table">
                <thead>
                <tr>
                    <th>Дата загрузки</th>
                    <th>Имя</th>
                    <th>Кол-во позиций</th>
                    <th>Статус</th>
                    <th className="d-none">Прогресс</th>
                    <th>Действия</th>
                </tr>
                </thead>
                <tbody>
                {files.map((file) => (
                    <tr key={file.id}>
                        <td>{formatDate(file.created_at)}</td>
                        <td>
                            {editingFile === file.id ? (
                                <input
                                    type="text"
                                    value={newFileName}
                                    onChange={(e) => setNewFileName(e.target.value)}
                                    onBlur={() => {
                                        onRename(file.id, newFileName);
                                        setEditingFile(null);
                                    }}
                                    autoFocus
                                />
                            ) : (
                                <div>
                                    <span>{file.display_name}</span><br/>
                                    {file.role === 'specification' ? (
                                        <small
                                            className="badge bg-success">Спецификация</small>
                                    ) : (
                                        <small
                                            className="badge bg-secondary">Прайс</small>
                                    )}
                                </div>
                            )}
                        </td>
                        <td>{file.number_of_items}</td>
                        <td>
                            {getStatusIcon(file.status)}
                            {file.status == "PROCESSING" && (
                                <span>
                                    <span
                                        className="spinner-border spinner-border-sm text-primary">
                                        <span
                                            className="visually-hidden">Обработка...</span>
                                    </span>
                                    <span
                                        className={"text-primary"}> {file.processing_progress}%</span>
                                </span>
                            )}
                            {file.status == "QUEUED" && (
                                <div
                                    className="spinner-grow spinner-grow-sm text-secondary">
                                    <span
                                        className="visually-hidden">Обработка...</span>
                                </div>
                            )}
                            {file.status == "PROCESSING_ERROR" && (
                                <span
                                    className="text-danger">{file.processing_user_error}</span>
                            )}
                        </td>
                        <td className="d-none">
                            <div className="progress-bar">
                                <div
                                    className="progress-bar-fill"
                                    style={{width: `${file.progress}%`}}
                                ></div>
                                <span className="progress-text">{file.progress}%</span>
                            </div>
                        </td>
                        <td>
                            <button
                                onClick={() => onView(file)}
                                className="action-button view-button"
                            >
                                <Eye size={18}/>
                            </button>
                            <button
                                onClick={() => {
                                    setSelectedFile(file);
                                    setShowParseModal(true);
                                }}
                                className={`btn ${isFileProcessed(file) ? 'btn-secondary' : 'btn-primary'}`}
                                disabled={false}
                            >
                                Добавить в базу
                            </button>
                            <button
                                onClick={() => {
                                    setEditingFile(file.id);
                                    setNewFileName(file.name);
                                }}
                                className="action-button edit-button"
                            >
                                <Edit2 size={18}/>
                            </button>
                            <button
                                onClick={() => {
                                    setSelectedFile(file);
                                    setShowDeleteModal(true);
                                }}
                                className="action-button delete-button"
                            >
                                <Trash2 size={18}/>
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            {/* Parse Confirmation Popup */}
            <Popup
                show={showParseModal}
                onClose={() => setShowParseModal(false)}
                title="Подтверждение"
                footer={
                    <>
                        <button type="button" className="btn btn-secondary"
                                onClick={() => setShowParseModal(false)}>Отмена
                        </button>
                        <button type="button" className="btn btn-primary m-lg-1"
                                onClick={handleParseConfirm}>Подтвердить
                        </button>
                    </>
                }
            >
                <p>Вы уверены, что хотите добавить
                    файл <b>"{selectedFile?.display_name}"</b> в базу?
                </p>
                <p>Процесс займет некоторое время</p>
            </Popup>

            {/* Delete Confirmation Popup */}
            <Popup
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                title="Подтверждение удаления"
                footer={
                    <>
                        <button type="button" className="btn btn-secondary"
                                onClick={() => setShowDeleteModal(false)}>Отмена
                        </button>
                        <button type="button" className="btn btn-danger"
                                onClick={handleDeleteConfirm}>Удалить
                        </button>
                    </>
                }
            >
                <p>Вы уверены, что хотите удалить файл
                    "{selectedFile?.display_name}"?</p>
            </Popup>
        </>
    );
};

const DropZone = ({onDrop, fileType}) => {
    const fileInputRef = useRef(null);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const files = e.dataTransfer.files;
        onDrop(files[0], fileType);
    };

    const handleClick = () => {
        fileInputRef.current.click();
    };

    return (
        <div
            className="dropzone"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            onClick={handleClick}
        >
            <Upload size={24}/>
            <p>Загрузите {fileType == "specification" ? "Спецификацию" : "Прайслист"} </p>
            <input
                ref={fileInputRef}
                type="file"
                onChange={(e) => onDrop(e.target.files[0], fileType)}
                style={{display: 'none'}}
            />
        </div>
    );
};

const FilesPage = () => {
    const [files, setFiles] = useState([]);

    useEffect(() => {
        fetchFiles();
    }, []);

    useEffect(() => {
        console.log("useEffect")
        const interval = setInterval(fetchFileStatuses, 3000);
        return () => clearInterval(interval);
    }, [files]);

    const fetchFiles = async () => {
        try {
            const response = await axios.get(`${config.BASE_API_URL}/api/files`);
            setFiles(response.data.map(file => ({...file, progress: 0})));
        } catch (error) {
            console.error('Error fetching files:', error);
            toast.error('Failed to fetch files');
        }
    };

    const fetchFileStatuses = async () => {
        console.log("fetchFileStatuses")
        const updatedFiles = await Promise.all(
            files.map(async (file) => {
                try {
                    const response = await axios.get(`${config.BASE_API_URL}/api/files/${file.id}`);
                    return {...file, ...response.data};
                } catch (error) {
                    console.error(`Error fetching status for file ${file.id}:`, error);
                    return file;
                }
            })
        );
        setFiles(updatedFiles);
    };

    const handleFileUpload = async (file, fileType) => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_type', fileType);

        try {
            await axios.post(`${config.BASE_API_URL}/api/files/upload`, formData, {
                headers: {'Content-Type': 'multipart/form-data'}
            });
            toast.success(`Файл ${file.name} успешно загружен`);
            await fetchFiles();
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error(`Не удалось загрузить файл ${file.name}`);
        }
    };

    const handleDeleteFile = async (fileUid) => {
        try {
            await axios.delete(`${config.BASE_API_URL}/api/files/${fileUid}`);
            toast.success(`File deleted successfully`);
            setFiles(files.filter(file => file.id !== fileUid));
        } catch (error) {
            console.error('Error deleting file:', error);
            toast.error(`Failed to delete file`);
        }
    };

    const handleRenameFile = async (fileUid, newName) => {
        try {
            await axios.put(`${config.BASE_API_URL}/api/files/${fileUid}`, {new_name: newName});
            toast.success(`File renamed to ${newName} successfully`);
            setFiles(files.map(file =>
                file.id === fileUid ? {...file, name: newName} : file
            ));
        } catch (error) {
            console.error('Error renaming file:', error);
            toast.error(`Failed to rename file`);
        }
    };

    const handleParseFile = async (fileUid) => {
        try {
            await axios.post(`${config.BASE_API_URL}/api/files/${fileUid}/process`);
            toast.success(`Началась обработка файла. Пожалуйста подождите`);
            // Update the file status locally
            setFiles(files.map(file =>
                file.id === fileUid ? {
                    ...file,
                    status: 'PROCESSING',
                    progress: 0
                } : file
            ));
        } catch (error) {
            console.error('Error processing file:', error);
            toast.error(`Ошибка обработки файла: ${error}`);
        }
    };

    const handleViewFile = (file) => {
        window.open(`${config.BASE_API_URL}/${file.view_items_signed_url}`, '_blank');
    };

    return (
        <div className="file-management">
            <div className="file-upload-section">
                <h2>Загрузите файлы</h2>
                <div className="dropzone-container">
                    <DropZone onDrop={(file) => handleFileUpload(file, 'specification')}
                              fileType="specification"/>
                    <DropZone onDrop={(file) => handleFileUpload(file, 'pricelist')}
                              fileType="pricelist"/>
                </div>
            </div>
            <div className="file-list-section">
                <h2>Файлы</h2>
                <FileTable
                    files={files}
                    onDelete={handleDeleteFile}
                    onRename={handleRenameFile}
                    onParse={handleParseFile}
                    onView={handleViewFile}
                />
            </div>
        </div>
    );
};

export default FilesPage;