import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

const Expenses = () => {
  const [expenses, setExpenses] = useState([]);
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [filterPeriod, setFilterPeriod] = useState('all');
  const [stats, setStats] = useState({
    today: 0,
    thisWeek: 0,
    thisMonth: 0,
    thisYear: 0,
  });

  useEffect(() => {
    fetchExpenses();
  }, []);

  useEffect(() => {
    filterExpenses();
    calculateStats();
  }, [expenses, filterPeriod]);

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/expenses`);
      setExpenses(response.data);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const filterExpenses = () => {
    const now = new Date();
    let filteredData = expenses;

    switch (filterPeriod) {
      case 'day':
        filteredData = expenses.filter(expense => new Date(expense.created_at) >= new Date(now.setHours(0,0,0,0)));
        break;
      case 'week':
        const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
        filteredData = expenses.filter(expense => new Date(expense.created_at) >= startOfWeek);
        break;
      case 'month':
        filteredData = expenses.filter(expense => new Date(expense.created_at) >= new Date(now.getFullYear(), now.getMonth(), 1));
        break;
      case 'year':
        filteredData = expenses.filter(expense => new Date(expense.created_at) >= new Date(now.getFullYear(), 0, 1));
        break;
      default:
        break;
    }

    setFilteredExpenses(filteredData);
  };

  const calculateStats = () => {
    const now = new Date();
    const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const startOfYear = new Date(now.getFullYear(), 0, 1);

    const todayTotal = expenses
      .filter(expense => new Date(expense.created_at) >= startOfDay)
      .reduce((total, expense) => total + parseFloat(expense.charging_cost), 0);

    const weekTotal = expenses
      .filter(expense => new Date(expense.created_at) >= startOfWeek)
      .reduce((total, expense) => total + parseFloat(expense.charging_cost), 0);

    const monthTotal = expenses
      .filter(expense => new Date(expense.created_at) >= startOfMonth)
      .reduce((total, expense) => total + parseFloat(expense.charging_cost), 0);

    const yearTotal = expenses
      .filter(expense => new Date(expense.created_at) >= startOfYear)
      .reduce((total, expense) => total + parseFloat(expense.charging_cost), 0);

    setStats({
      today: todayTotal.toFixed(2),
      thisWeek: weekTotal.toFixed(2),
      thisMonth: monthTotal.toFixed(2),
      thisYear: yearTotal.toFixed(2),
    });
  };

  const calculateTotalCost = () => {
    return filteredExpenses.reduce((total, expense) => total + parseFloat(expense.charging_cost), 0).toFixed(2);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  return (
    <div className="expenses">
      <h1 className="mb-4">Расходы</h1>

      <div className="dashboard-stats mb-4">
        <div className="row g-4">
          <div className="col-md-3">
            <div className="card h-100" style={{backgroundColor: '#E6F3FF', color: '#0066CC'}}>
              <div className="card-body">
                <h6 className="card-title mb-2">Сегодня</h6>
                <h2 className="mb-0">${stats.today}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card h-100" style={{backgroundColor: '#E6FFF2', color: '#009966'}}>
              <div className="card-body">
                <h6 className="card-title mb-2">За неделю</h6>
                <h2 className="mb-0">${stats.thisWeek}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card h-100" style={{backgroundColor: '#FFF2E6', color: '#CC6600'}}>
              <div className="card-body">
                <h6 className="card-title mb-2">За месяц</h6>
                <h2 className="mb-0">${stats.thisMonth}</h2>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="card h-100" style={{backgroundColor: '#FFE6E6', color: '#CC0000'}}>
              <div className="card-body">
                <h6 className="card-title mb-2">За год</h6>
                <h2 className="mb-0">${stats.thisYear}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-4">
        <label htmlFor="filterPeriod" className="form-label">Фильтр по периоду:</label>
        <select
          id="filterPeriod"
          className="form-select"
          value={filterPeriod}
          onChange={(e) => setFilterPeriod(e.target.value)}
        >
          <option value="all">Все время</option>
          <option value="day">За день</option>
          <option value="week">За неделю</option>
          <option value="month">За месяц</option>
          <option value="year">За год</option>
        </select>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-hover">
          <thead className="table-light">
            <tr>
              <th>Дата</th>
              <th>Название</th>
              <th>Стоимость</th>
            </tr>
          </thead>
          <tbody>
            {filteredExpenses.map(expense => (
              <tr key={expense.id}>
                <td>{formatDate(expense.created_at)}</td>
                <td>{expense.name}</td>
                <td>${expense.charging_cost}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="table-active">
              <td colSpan="2"><strong>Итого:</strong></td>
              <td><strong>${calculateTotalCost()}</strong></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Expenses;