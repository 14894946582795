import React, { useState } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import PrettyPrintJson from "../utils/utils";

const ExpandableCell = ({ content, buttonText = "Характеристики" }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div>
      <button
        onClick={toggleExpand}
        className="btn btn-link p-0 text-decoration-none d-inline-flex align-items-center text-muted pt-1"
      >
        {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
        <small className="ms-1">{buttonText}</small>
      </button>
      {isExpanded && (
        <div className="mt-2">
          <PrettyPrintJson data={content} />
        </div>
      )}
    </div>
  );
};

export default ExpandableCell;