import React, {useState} from "react";

const PrettyPrintJson = ({data}) => {
    const [copySuccess, setCopySuccess] = useState(false);

    const copyToClipboard = () => {
      navigator.clipboard.writeText(JSON.stringify(data, null, 2)).then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      });
    };

    return (
        <div className="position-relative">
      <pre className="bg-light p-3 rounded">
        <code className="small">
          {JSON.stringify(data, null, 2)}
        </code>
      </pre>
        </div>
    );
  };

export default PrettyPrintJson;