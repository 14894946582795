import { v4 as uuidv4 } from 'uuid';

class SearchTask {
  constructor(data) {
    this.id = data.id || uuidv4();
    this.createdAt = data.created_at ? new Date(data.created_at) : new Date();
    this.projectId = data.project_id || null;
    this.specItemId = data.spec_item_id;
    this.result = data.result || {};
    this.logs = data.logs || "";
    this.usage = data.usage || {};
    this.status = data.status || "";
  }
}

export default SearchTask;