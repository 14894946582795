import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Home,
  Upload,
  Search,
  Settings,
  Briefcase,
  LogOut,
  User,
  DollarSign
} from 'lucide-react';
import { AuthContext } from '../App';
import Expenses from "../pages/Expenses";

const Menu = () => {
  const { username, logout } = useContext(AuthContext);
  const history = useHistory();

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  return (
    <nav className="menu d-flex flex-column h-100">
      <ul className="flex-grow-1">
        <li><Link to="/"><Home size={24}/> Home</Link></li>
        <li><Link to="/projects"><Briefcase size={24}/> Проекты</Link></li>
        <li><Link to="/files"><Upload size={24}/> Файлы</Link></li>
        <li><Link to="/search"><Search size={24}/> Поиск</Link></li>
        <li><Link to="/expenses"><DollarSign size={24}/> Расходы</Link></li>
        <li><Link to="/settings"><Settings size={24}/> Настройки</Link></li>
      </ul>
      <div className="mt-auto p-2">
        <div className="user-info mb-2">
          <User size={24} />
          <span className="ml-2">{username}</span>
        </div>
        <button onClick={handleLogout} className="btn btn-outline-danger w-100">
          <LogOut size={24} /> Выйти
        </button>
      </div>
    </nav>
  );
};

export default Menu;