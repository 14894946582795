import React, { useEffect, useState } from 'react';
import './Popup.css'; // We'll create this CSS file next

const Popup = ({ show, onClose, title, children, footer }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      setIsVisible(true);
    } else {
      const timer = setTimeout(() => setIsVisible(false), 300); // Match this with your transition duration
      return () => clearTimeout(timer);
    }
  }, [show]);

  if (!isVisible && !show) {
    return null;
  }

  return (
    <div className={`popup-overlay ${show ? 'show' : ''}`} onClick={onClose}>
      <div
        className={`popup-content ${show ? 'show' : ''}`}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-header">
          <h5 className="modal-title">{title}</h5>
          <button type="button" className="btn-close" onClick={onClose}></button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        {footer && (
          <div className="modal-footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;