import React from 'react';
import { Plus } from 'lucide-react';
import ExpandableCell from "./ExpandableCell";
import VSEInstrumentiButton from "./buttons/VseInstrumentiButton";
import config from '../config';

const SearchResultsTable = ({ results, files, onAddMatch, specItemId }) => {
  // console.log("SearchResultTable results=", results)
  if (Object.keys(results).length === 0) {
    return <div className="mt-3 ps-5 alert alert-warning">Не найдено позиций с указанной спецификацией</div>;
  }

  // Sort files: files with results first, then by highest similarity of first item, then by lowest price of first item
  const sortedFiles = Object.entries(results).sort((a, b) => {
    if (a[1].length === 0 && b[1].length > 0) return 1;
    if (a[1].length > 0 && b[1].length === 0) return -1;
    if (a[1].length === 0 && b[1].length === 0) return 0;

    // Sort by similarity (descending order)
    const similarityDiff = b[1][0].similarity - a[1][0].similarity;
    if (similarityDiff !== 0) return similarityDiff;

    // If similarity is the same, sort by price (ascending order)
    return (a[1][0].price_item.price_rrc || Infinity) - (b[1][0].price_item.price_rrc || Infinity);
  });

  return (
    <div className="mt-3 ps-5">
      {sortedFiles.map(([fileId, fileResults]) => (
        <div key={fileId} className="mb-4">
          <h3 className="fs-5 mb-3">{files[fileId]?.display_name || 'Unknown File'}</h3>
          {fileResults.length === 0 ? (
            <div className="alert alert-warning">Не найдено позиций с указанной спецификацией</div>
          ) : (
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Наименование</th>
                    <th>Артикул</th>
                    <th>Категория</th>
                    <th>Цена</th>
                    <th>Пояснение</th>
                    <th>Действия</th>
                  </tr>
                </thead>
                <tbody>
                  {fileResults
                    .sort((a, b) => {
                      // Sort by similarity (descending order)
                      const similarityDiff = b.similarity - a.similarity;
                      if (similarityDiff !== 0) return similarityDiff;

                      // If similarity is the same, sort by price (ascending order)
                      return (a.price_item.price_rrc || Infinity) - (b.price_item.price_rrc || Infinity);
                    })
                    .map((result, index) => (
                    <tr key={index} className={result.matched ? 'table-success' : ''}>
                      <td>
                        {result.price_item.name}<br/>
                        <small className="badge bg-secondary">{result.price_item.main_attributes_string}</small>
                        <ExpandableCell content={result.price_item.spec}/>
                      </td>
                      <td>{result.price_item.product_id}</td>
                      <td>{result.price_item.category || 'N/A'}</td>
                      <td>{result.price_item.price_rrc || 'N/A'}р</td>
                      <td>{result.explanation}</td>
                      <td>
                        {onAddMatch &&
                        <button
                          className="btn btn-sm btn-success"
                          onClick={() => onAddMatch(specItemId, result.price_item.id)}
                        >
                          <Plus size={16} className="me-1"/>
                          Выбрать
                        </button>
                        }
                        <VSEInstrumentiButton itemName={result.price_item.name}/>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SearchResultsTable;