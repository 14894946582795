import React from 'react';
import { ExternalLink } from 'lucide-react';

const VSEInstrumentiButton = ({ itemName }) => {
  const handleVseSearch = () => {
    const encodedName = encodeURIComponent(itemName);
    window.open(`https://www.vseinstrumenti.ru/search/?what=${encodedName}`, '_blank');
  };

  return (
    <button
      className="btn btn-danger"
      onClick={handleVseSearch}
    >
      <ExternalLink size={18} className="me-2" />
      В.И.
    </button>
  );
};

export default VSEInstrumentiButton;