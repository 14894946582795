import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Plus, Edit2, Trash2, FileText, List, AlarmSmoke, X } from 'lucide-react';
import { toast } from 'react-toastify';
import config from '../config';
import {useHistory} from "react-router-dom";

const Projects = () => {
  const history = useHistory();
  const [projects, setProjects] = useState([]);
  const [files, setFiles] = useState([]);
  const [newProject, setNewProject] = useState({ name: '', description: '' });
  const [editingProject, setEditingProject] = useState(null);

  useEffect(() => {
    fetchProjects();
    fetchFiles();
  }, []);

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const monthNames = {
      0: 'января',
      1: 'февраля',
      2: 'марта',
      3: 'апреля',
      4: 'мая',
      5: 'июня',
      6: 'июля',
      7: 'августа',
      8: 'сентября',
      9: 'октября',
      10: 'ноября',
      11: 'декабря'
    };
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/projects`);
      setProjects(response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      toast.error('Failed to fetch projects');
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`${config.BASE_API_URL}/api/files`);
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
      toast.error('Failed to fetch files');
    }
  };

  const handleCreateProject = async () => {
    try {
      await axios.post(`${config.BASE_API_URL}/api/projects`, newProject);
      toast.success('Project created successfully');
      setNewProject({ name: '', description: '' });
      fetchProjects();
    } catch (error) {
      console.error('Error creating project:', error);
      toast.error('Failed to create project');
    }
  };

  const handleUpdateProject = async (project) => {
    try {
      await axios.put(`${config.BASE_API_URL}/api/projects/${project.id}`, project);
      toast.success('Проект успешно обновлен');
      setEditingProject(null);
      fetchProjects();
    } catch (error) {
      console.error('Error updating project:', error);
      toast.error('Ошибка при обновлении проекта');
    }
  };

  const handleDeleteProject = async (projectUid) => {
    if (window.confirm('Are you sure you want to delete this project?')) {
      try {
        await axios.delete(`${config.BASE_API_URL}/api/projects/${projectUid}`);
        toast.success('Проект успешно удален');
        fetchProjects();
      } catch (error) {
        console.error('Error deleting project:', error);
        toast.error('Ошибка при удалении проекта');
      }
    }
  };

  const handleWorkProject = (project) => {
    history.push(`/projects/${project.id}`);
  };

  return (
    <div className="projects-page container mt-4">
      <h1 className="mb-4">Проекты</h1>
      <div className="create-project mb-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Название проекта"
            value={newProject.name}
            onChange={(e) => setNewProject({ ...newProject, name: e.target.value })}
          />
          <input
            type="text"
            className="form-control"
            placeholder="Описание проекта"
            value={newProject.description}
            onChange={(e) => setNewProject({ ...newProject, description: e.target.value })}
          />
          <button className="btn btn-primary" onClick={handleCreateProject}>
            <Plus size={18} /> Создать проект
          </button>
        </div>
      </div>
      <div className="projects-list">
        <table className="table table-striped table-hover">
          <thead>
          <tr>
            <th>Название</th>
            <th>Создан</th>
            <th>Описание</th>
            <th>Спецификация</th>
            <th>Прайс-листы</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
                <tr key={project.id}>
                  <td>
                    {editingProject === project.id ? (
                        <input
                            type="text"
                            className="form-control"
                            value={project.name}
                            onChange={(e) => setProjects(projects.map(p => p.id === project.id ? {
                              ...p,
                              name: e.target.value
                            } : p))}
                        />
                    ) : (
                        <button className={"btn btn-sm btn-primary"}
                                onClick={() => handleWorkProject(project)}>{project.name}</button>
                    )}
                  </td>
                  <td>{formatDate(project.created_at)}</td>
                  <td>
                    {editingProject === project.id ? (
                        <input
                            type="text"
                            className="form-control"
                            value={project.description}
                            onChange={(e) => setProjects(projects.map(p => p.id === project.id ? {
                              ...p,
                              description: e.target.value
                            } : p))}
                        />
                    ) : (
                        project.description
                    )}
                  </td>
                  <td>
                    <select
                        className="form-select"
                        value={project.specification_file_id || ''}
                        onChange={(e) => handleUpdateProject({
                          ...project,
                          specification_file_id: e.target.value
                        })}
                    >
                      <option value="">Выберите файл</option>
                      {files.filter(f => f.role === 'specification').map(file => (
                          <option key={file.id} value={file.id}>{file.name}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <select
                        className="form-select"
                        value=""
                        onChange={(e) => handleUpdateProject({
                          ...project,
                          price_file_ids: [...project.price_file_ids, e.target.value]
                        })}
                    >
                      <option value="">Добавить прайс-лист</option>
                      {files.filter(f => f.role === 'pricelist').map(file => (
                          <option key={file.id}
                                  value={file.id}>{file.display_name}</option>
                      ))}
                    </select>
                    <table className="table table-sm">
                      <tbody>
                      {project.price_file_ids.map(fileUid => {
                        const file = files.find(f => f.id === fileUid);
                        return file ? (
                            <tr key={fileUid}>
                              <td>{file.display_name}</td>
                              <td className="text-end">
                                <button
                                    className="btn btn-sm btn-outline-danger"
                                    onClick={() => handleUpdateProject({
                                      ...project,
                                      price_file_ids: project.price_file_ids.filter(uid => uid !== fileUid)
                                    })}
                                >
                                  <X size={16}/>
                                </button>
                              </td>
                            </tr>
                        ) : null;
                      })}
                      </tbody>
                    </table>
                  </td>
                  <td>
                    {editingProject === project.id ? (
                        <>
                          <button className="btn btn-sm btn-success me-2"
                                  onClick={() => handleUpdateProject(project)}>Сохранить
                          </button>
                          <button className="btn btn-sm btn-secondary"
                                  onClick={() => setEditingProject(null)}>Отмена
                          </button>
                        </>
                    ) : (
                        <>
                          <button className="btn btn-sm btn-warning me-2"
                                  onClick={() => setEditingProject(project.id)}><Edit2
                              size={18}/> Редактировать
                          </button>
                          <button className="btn btn-sm btn-danger"
                                  onClick={() => handleDeleteProject(project.id)}>
                            <Trash2
                                size={18}/> Удалить
                          </button>
                        </>
                    )}
                  </td>
                </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Projects;