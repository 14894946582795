import React from 'react';
import { Link } from 'react-router-dom';
import { Upload, FolderPlus, Search } from 'lucide-react';

const FeatureButton = ({ to, icon: Icon, title, description }) => (
  <Link to={to} className="text-decoration-none">
    <div className="card h-100 shadow-sm hover-shadow transition">
      <div className="card-body d-flex flex-column align-items-center text-center">
        <Icon size={48} className="mb-3 text-primary" />
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
      </div>
    </div>
  </Link>
);

const Home = () => {
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <h2 className="text-center mb-5">Artool AI</h2>
          <div className="row g-4">
            <div className="col-md-4">
              <FeatureButton
                to="/upload"
                icon={Upload}
                title="Загрузка файлов"
                description="Загрузите файлы прайсов и спецификаций"
              />
            </div>
            <div className="col-md-4">
              <FeatureButton
                to="/projects"
                icon={FolderPlus}
                title="Создание проекта"
                description="Создайте проект для поиска по спецификации"
              />
            </div>
            <div className="col-md-4">
              <FeatureButton
                to="/search"
                icon={Search}
                title="Поиск по прайсам"
                description="Ищите по загруженным прайсам"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;